@use '@angular/material' as mat;

// Include the common styles for Angular Material
@include mat.core();

$dark-primary-text: rgba(black, 0.87);
$light-primary-text: white;
$text-default-color: #323232;
$text-header-table-color: #686868;

$blue-palette: (
  50: #e1f5ff,
  100: #b4e5ff,
  200: #81d4ff,
  300: #4cc3ff,
  400: #23b6ff,
  500: #0aa9ff,
  600: #139af5,
  700: #1887e0,
  800: #1776cc,
  900: #1955a9,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

$green-palette: (
  50: #e0f8f9,
  100: #b3eef1,
  200: #82e2e9,
  300: #4fd6e0,
  400: #27cdda,
  500: #00c3d5,
  600: #00b3c2,
  700: #009ea8,
  800: #008991,
  900: #006666,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $dark-primary-text,
    400: $dark-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  ),
);

// Define your custom theme
$my-primary: mat.define-palette($green-palette, 900);
// $my-primary: mat.define-palette(mat.$indigo-palette);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$my-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
    ),
  )
);

:root {
  // Override button styles
  --mdc-extended-fab-container-shape: 4px;
  --mdc-extended-fab-container-elevation-shadow: none;

  // Override mat-tab-group styles
  --mat-tab-header-divider-color: #e0e2ec;
  --mat-tab-header-divider-height: 1px;

  // Override dialog styles
  --mat-dialog-actions-alignment: flex-end;

  // Change mat-form-field background color
  // --mdc-filled-text-field-container-color: #f0f0f0;
}

.text-primary {
  path {
    fill: hsl(var(--primary));
  }
}

// Include the theme styles for all components
@include mat.all-component-themes($my-theme);

.mat-mdc-tab-label-container {
  border-bottom-color: var(--mat-tab-header-divider-color);
  border-bottom-width: var(--mat-tab-header-divider-height);
}

.mat-mdc-dialog-actions {
  justify-content: var(--mat-dialog-actions-alignment, start);
}

.mat-mdc-extended-fab {
  border-radius: var(--mdc-extended-fab-container-shape);
  box-shadow: var(--mdc-extended-fab-container-elevation-shadow);
}

// Override mat-table styles

.mat-mdc-table.ws-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 8px;
  margin-top: -16px;
  background-color: transparent;
  font-family: Open Sans;
  overflow-x: auto;
  overflow-y: auto;
  margin-bottom: 0;

  .mat-mdc-table tbody,
  .mat-mdc-table tfoot,
  .mat-mdc-table thead,
  .mat-mdc-cell,
  .mat-mdc-footer-cell,
  .mat-mdc-header-row,
  .mat-mdc-footer-row,
  .mat-mdc-table .mat-mdc-header-cell {
    background: inherit;
    border: none;
    padding: 8px 0 8px 14px;
    margin-top: 8px;
    line-height: 20px;
    font-family: Open Sans;
    color: $text-default-color;
    align-items: stretch;
    cursor: pointer;
    transition: background 0.2s;
  }

  .mat-mdc-header-row {
    font-weight: var(--mat-table-header-headline-weight, 700);
  }

  .mat-mdc-header-cell {
    border-bottom-color: transparent;
    border-bottom-width: 0px;
    color: $text-header-table-color;
  }

  .mat-mdc-row {
    background: white;
    border: 1px solid var(--mat-tab-header-divider-color);
    margin-top: 8px;
    min-height: 40px;
    font-size: 14px;
    line-height: 20px;
    font-family: Open Sans;
    font-weight: 400;
    color: $text-default-color;
    align-items: stretch;
    cursor: pointer;
    transition: background 0.2s;

    &:hover {
      background: rgba(black, 0.08);
    }
  }

  td.mat-mdc-cell {
    border-top: 1px solid var(--mat-tab-header-divider-color);
    border-bottom: 1px solid var(--mat-tab-header-divider-color);
  }

  td.mat-mdc-cell:first-child {
    border-left: 1px solid var(--mat-tab-header-divider-color);
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  td.mat-mdc-cell:last-child {
    border-right: 1px solid var(--mat-tab-header-divider-color);
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
}
